import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class TutorialService{
    key =localStorage.getItem('apiKey')
    async getTutorialVideos(lang_id,searchvalue){
        const response = await fetch(BASE_URL+'resources/tutorilas?lang_id='+lang_id+'&keyword='+searchvalue, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
}