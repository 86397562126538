import React from "react";
import style from "./cart.module.scss";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddonNotification, AlertBs } from "../common";
import CartTable from "./CartTable";
import Nophoto from "../../../assets/images/cart/cart.jpg";
import { FormInputControl } from "../common";
import { useState } from "react";
import { isNumber } from "../../validation";
import shoppingService from "../../../service/shopping";
import { Link } from "react-router-dom";
import { AlertMessage } from "../common";

const CartContent = (props) => {
  const service = new shoppingService();
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  const currency = useSelector((state) => state.curr);

  const viewPackage = (id) => {
    history.push(`/package_details/${id}`);
  };
  const [state, setState] = useState({
    amount: "",
    IsError: false,
    notifyMessage: "",
    notyfiType: "",
    header: "Error!",
    validated: false,
    FormError: {
      amount: "",
    },
    loader: false,
  });

  const { FormError } = state

  const textChangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    FormError.amount = "";
    setState((prev) => ({
      ...prev,
      validated: false,
      [name]: value,
    }));
  };


  const validation = (name) => {
    // const FormError = FormError;
    const values = state;
    let valid = true;
    let error = "";
    switch (name) {

      case "amount":
        if (values.amount == "") {
          valid = false;
          FormError.amount = t("validation.enterAmount");
          error = t("validation.enterAmount");
        } else if (!isNumber(values.amount)) {
          valid = false;
          FormError.amount = t("validation.onlyDigitsAllowed");
          error = t("validation.onlyDigitsAllowed");
        } else if (values.amount <= 0) {
          valid = false;
          FormError.amount = t("validation.valueGreaterThan0");
          error = t("validation.valueGreaterThan0");
        }
        //  else if (values.amount < 200 || values.amount > 10000) {
        //   valid = false;
        //   FormError.amount = t("validation.valuebetween200and10000");
        //   error = t("validation.valuebetween200and10000");
        // } 
        else {
          FormError.amount = "";
          valid = true;
        }
        break;

      default:
        valid = true;
    }
    if (!valid && error) {
      setState((prev) => ({
        ...prev,
        notifyMessage: t("validation.checkValueYouHaveSubmitted"),
      }));
    }
    setState((prev) => ({
      ...prev,
      FormError,
      validated: !valid,
    }));
    return valid;
  };

  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      IsError: false,
      notifyMessage: "",
    }));

  };

  const submitForm = (e) => {
    e.preventDefault();
    let valid = true;
    const validamount = validation("amount") ?? false;

    if (validamount) {
      // let formData = new FormData();
      let formData = {
        amount: state.amount,
      };
      
      // formData.append("to_user_name", state.toUsername);
      // formData.append("amount", NewAmount);
      // formData.append("transaction_note", state.Notes);
      // formData.append("pswd", state.Password);
      // formData.append("tran_fee", transFee);
      setState((prev) => ({
        ...prev,
        loader: true,
      }));
      service.addToCart(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            Amount: "",
            FormError,
            validated: !valid,
            // IsError: true,
            // notifyMessage: t("common.amon"),
            // notyfiType: "success",
            // header: t("Common.success"),
            loader: false,
          }));

          props.getcartitem()

        } else {
          let error = "";
          switch (res.error.code) {
            case 1015:
              FormError.Password = t("validation.invalid", {
                field: "transactionPassword",
              });
              error = t("validation.invalid", { field: "transactionPassword" });
              break;
            case 406:
              error = "Not Acceptable";
              break;
            case 1072:
              error = t("validation.cannotTransfersameuser");
              break;
            case 1074:
              error = t("validation.cannotaddmorepack");
              break;
              case 1073:
              error = t("validation.alreadyselectedamount");
              break;
            default:
              break;
          }
          setState((prev) => ({
            ...prev,
            FormError,
            validated: true,
            IsError: true,
            notifyMessage: error,
            notyfiType: "danger",
            header: t("Common.error"),
            loader: false,
          }));
        }
      });
    } else {
      setState((prev) => ({
        ...prev,
        FormError,
        validated: !valid,
        IsError: true,
        notyfiType: "danger",
        header: t("Common.error"),
        loader: false,
      }));
    }
  };
  return (
    <div className={style.container}>
      {
        state.IsError && (
          <AlertMessage
            message={state.notifyMessage}
            dismiss={notifyDissmissed}
            type={state.notyfiType}
            header={state.header}
            show={state.IsError}
          />
        )


      }

      <Card>
        <Card.Body>
          {
            props.cart && props.cart.length > 0 &&
            <CartTable cart={props.cart}
            />
          }{
            props.cart.length == 0 && <FormInputControl
            label={t("Common.amount")}
            isRequired={true}
            error={{
              error: FormError.amount,
            }}
            inputProps={{
              name: "amount",
              onChange: textChangeHandler,
              value: state.amount,
              placeholder: t("ewallet.enterAmount"),
            }}
          />
          }
          

          <Button onClick={submitForm}>{t('Common.add')}</Button>


        </Card.Body>
      </Card>



    </div>
  );
};

export default CartContent;
