import React from 'react'
import { useTranslation } from "react-i18next";
//compoents
import Tabs from "react-responsive-tabs";
import style from "./ewallet.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import RoiTable from './RoiTable';

const stylees = {
    paddingTop: "10px",
    fontFamily: "system-ui",
};
function TabContent({
    tableData,
    paginationHandler,
    tabChangeHandler
}) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const { t } = useTranslation();
    const [tabs, setTabs] = useState([
        "roitable",
    ]);

    const getTabs = () => {
        return tabs.map((tab, index) => ({
            title: t("Common." + tab),
            getContent: () => getContent(tab),
            /* Optional parameters */
            key: tab,
            tabClassName: style.TabClass,
            panelClassName: style.TabPanel,
            // tabsWrapperClass : 'safdsadfads'
        }));
    };

    const getContent = (contentId) => {
        if (contentId === "roitable") {
            return (
                <div className={style.dataTable} style={stylees}>
                    <RoiTable
                        tableData={tableData}
                        paginationHandler={paginationHandler}
                    />
                </div>
            )
        }

    }
    return (
        <div className={style.summary}>
            <div
                className={`${currentTheme == "theme-dark"
                        ? `${style.dark_mode_tabContent}`
                        : `${style.tabContent}`
                    }`}
            >
                <Tabs
                    items={getTabs()}
                    transform={true}
                    transformWidth={734}
                    onChange={tabChangeHandler}
                    selectedTabKey="roitable"
                />
            </div>
        </div>
    )
}

export default TabContent