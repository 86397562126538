import React,{useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import style from './registration.module.scss';
import {Paneltilte,Buttons} from './';
import FormsControl from './FormsControl';
import { useSelector } from "react-redux";

function ContactInformation(props) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const [data,setData] = useState([])
    //change handler
    const changehandler = (e) =>{
        props.ChangeHandler(e,'contactInfo')
    }
    useEffect(()=>{
        let newData= props.data.map(object=>{
            if(object.code === 'gender'){
                let newOption= object.options.map((option)=>{
                    return{
                        ...option,
                        code : ('profile.'+option.code)
                    }
                })
                return{
                    ...object,
                    options : newOption
                }
            }else{
                return object
            }
        });
        setData(newData);
    },[props.data])
    return (
        <div className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_SponsorPackage}`
              : `${style.SponsorPackage}`
          }`}>
            <Paneltilte 
                tilte="contactInformation"
            />
            <Form onSubmit={(e)=>{props.submit(e,"",'contactInfo')}}>
            {
                data.map((field,index)=>{
                    return <FormsControl
                    key={index} 
                    data={field}
                    change={changehandler} 
                    refs={props.refs}
                    selectDate={props.selectDate}
                    Filechangehandler={props.Filechangehandler}
                    selectedFileurl={props.selectedFileurl}
                    filelabel={props.filelabel}
                    /> 
                })
            }
            <Buttons
                prev={true}
                prevButtonClick={props.prevButtonClick}
            />
            </Form>
        </div>
    )
}

export default ContactInformation
 