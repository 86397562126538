import React, { useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import shoppingService from '../../../service/shopping';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CurrencyDecimalFormat } from '../../helper';
import { AlertMessage } from '../common';
import { useHistory } from 'react-router';
import moment from 'moment'
import DataTable from 'react-data-table-component';
function ReinjectContent() {
    const { t } = useTranslation();
    const service = new shoppingService();
    const [amount, setamount] = useState(0)
    const history = useHistory();
    const [alertmsg, setalertmsg] = useState({
        notify: {
            message: '',
            show: false,
            varient: ''
        }
    })
    const [data, setdata] = useState({
        data: [],
        perPage: 10,
        inPage: 1,
        totalRows:0
    })
    const Currency = useSelector((state) => state.curr);


    useEffect(() => {
        getReinjectdata();

    }, [])

    const getReinjectdata = (
        inPage = data.inPage,
        perPage = data.perPage,
    ) => {
        let start = (inPage - 1) * perPage;
        service.getreinjectAmount(start,perPage).then((res) => {
            if (res.status) {
                setamount(res.data.balanceDetails.balance)
                setdata((prev) => ({
                    ...prev,
                    data: res.data.result,
                    totalRows:res.data.count
                }))
            }
        })
    }
    const columns = [
        {
            name: t("Common.id").toUpperCase(),
            selector: (row) => row.id,
            width: "30%"
        },
        {
            name: t("Common.amount"),
            selector: (row) => row.amount,
            cell: (row) => (
                <span>
                    {Currency.currentCurr}{" "}
                    {CurrencyDecimalFormat(
                        row.amount * Currency.value,
                        Currency.precision
                    )}

                </span>
            )
        },
        {
            name: t("Common.date"),
            selector: (row) => moment(row.date).format('YYYY-MM-D')
        },
        {
            selector: (row) => row.id,
            cell: (row) => (
                <Button onClick={() => handleReinject(row.id)}>{t('Common.reinject')}</Button>
            )
        }
    ]

    useEffect(() => {
        let TableData = data.data.map((resdata) => {
            return {
                id: resdata.id,
                amount: resdata.amount,
                date:resdata.date
            }
        })
        setdata((prev) => ({
            ...prev,
            data: TableData
        }))

    }, [])

  
    const handleReinject = (id) => {
        const formdata={
            id:id
        }
        service.reinject(formdata).then((res) => {
            if (res.status) {
                history.push({
                    // pathname: `/repurchaseInvoice/${res.data.enc_order_id}`,
                    pathname: `/repurchaseInvoice/${res.data.enc_order_id}`,
                    state: {
                        invoice_no: res.data.invoice_no,
                        pending: res.data.pending,
                    },
                });
            } else if (res.error.code === 1076) {
                setalertmsg((prev) => ({
                    ...prev,
                    notify: {
                        ...prev.notify,
                        show: true,
                        varient: 'danger',
                        message: t(`validation.${res.error.description}`)
                    }
                }))
            } else {
                setalertmsg((prev) => ({
                    ...prev,
                    notify: {
                        ...prev.notify,
                        show: true,
                        varient: 'danger',
                        message: t(`validation.${res.error.description}`)
                    }
                }))
            }
        })
    }

    const notifyDissmissed = () => {
        setalertmsg((prev) => ({
            ...prev,
            notify: {
                message: '',
                show: false,
                varient: ''
            }
        }))
    }
    const handlePageChange=(inPage)=>{
        setdata((prev)=>({
            ...prev,
            inPage:inPage,
            perPage:10
        }))
        getReinjectdata(inPage,data.perPage)
    }

    const handlePerRowsChange=(perPage,inPage)=>{
        setdata((prev)=>({
            ...prev,
            inPage:inPage,
            perPage:perPage
        }))
        getReinjectdata(inPage,perPage)
    }
    return (
        <div className='mt-3'>
            {
                alertmsg.notify.show && (
                    <AlertMessage
                        message={alertmsg.notify.message}
                        dismiss={notifyDissmissed}
                        type={alertmsg.notify.varient}
                        show={alertmsg.notify.show}
                    />
                )
            }

            <Card className='m-3'>
                <Card.Body>
                    <Card.Text>
                        <span style={{ fontSize: '16px', fontWeight: 'bolder' }}>{t('Common.balanceamount')}&nbsp;:&nbsp;{Currency.currentCurr}{" "}
                            {CurrencyDecimalFormat(amount * Currency.value, Currency.precision)}</span>
                    </Card.Text>
                    <DataTable
                        columns={columns}
                        data={data.data}
                        highlightOnHover
                        // progressPending={ContentData.pending}
                        progressComponent={<div>Loading</div>}
                        persistTableHead
                        noHeader
                        pagination
                        paginationServer
                        paginationTotalRows={data.totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        noDataComponent={t("Common.noDataRecordsToDisplay")}
                        responsive
                    />
                </Card.Body>

            </Card>
        </div>
    )
}

export default ReinjectContent