import { Row, Col, Card, Form, Button } from "react-bootstrap";
import React from "react";
import style from "./tools.module.scss";
import AddonNotification from "../../components/common/AddonNotification";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AlertBs from "../common/AlertBs";

const LeadContent = (props) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: t("Common.name"),
      cell: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      name: t("Common.sponsorName"),
      cell: (row) =>
        `${row.user_detail_name} ${row.user_detail_second_name} (${row.sponser_name})`,
    },
    {
      name: t("Common.email"),
      selector: (row) => row.email,
      sortable: true,
      right: true,
    },
    {
      name: t("Common.phone"),
      selector: (row) => row.phone,
      sortable: true,
      right: true,
    },
    {
      name: t("Common.date"),
      cell: (row) => moment(row.date).format("D MMM YY-h:mm:ss a"),
      sortable: true,
    },
    {
      name: t("Common.status"),
      selector: (row) => row.status,
      sortable: true,
      right: true,
    },
    {
      name: t("Common.action"),
      cell: (row) => (
        <Button onClick={() => props.editClick(row.id)}>
          {t("Button.edit")}
        </Button>
      ),
    },
  ];

  return (
    <div className={style.downloadConatiner}>
      <div className={style.userDowmloadSection}></div>

      <Row>
        <Col>
          {/* <AlertBs {...props.alert} close={props.closeAlert} /> */}
          <Card className={style.search}>
            <Card.Body>
              <Form onSubmit={props.filterLeads}>
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("Common.keyword")}</Form.Label>
                      <Form.Control
                        size="xs"
                        type="text"
                        value={props.keyword}
                        onChange={props.keywordChange}
                        placeholder={t("Common.NameEmailPhone")}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button variant="info" type="submit" className="mtp30">
                      {t("Button.search")}
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={props.resetKeyword}
                      className="mtp30 ml-1"
                    >
                      {t("Button.reset")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>
                <span>
                  {t("Common.leadCaptureLink")}:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={props.lead_url}
                    style={{ textTransform: "lowercase" }}
                  >
                    {props.lead_url}
                  </a>
                </span>
              </Card.Title>
              <DataTable columns={columns} data={props.leads} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LeadContent;
