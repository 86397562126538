import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class ProductsService{
    key =localStorage.getItem('apiKey')
    async getGraphData(){
        const response = await fetch(BASE_URL+'products/returns_graph', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }

    async getRoitableDAta(start=0,length=10){
        const response = await fetch(BASE_URL+'products/product_dashboard?length='+length+'&start='+start+'', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
}