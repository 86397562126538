import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./ewallet.module.scss";
import { useHistory } from "react-router";
function RoiTable({ tableData, paginationHandler }) {
    const { t } = useTranslation();
    const Currency = useSelector((state) => state.curr);
    const history = useHistory();
    const columns = [
        {
            name: t("Common.productamount").toUpperCase(),
            selector: (row) => row.product_amount,
            cell: (row) => (
                <span className={``}>
                    {Currency.currentCurr}{" "}
                    {(row.product_amount * Currency.value).toFixed(Currency.precision)}
                </span>
            ),
        },
        {
            name: t("Common.date").toUpperCase(),
            selector: (row) => row.date,
        },
        {
            name: t("Common.packageName").toUpperCase(),
            selector: (row) => row.packageName,
        },
        {
            name: t("Common.totalyield").toUpperCase(),
            selector: (row) => row.total_yield,
            cell: (row) => (
                <span className={``}>
                    {Currency.currentCurr}{" "}
                    {(row.total_yield * Currency.value).toFixed(Currency.precision)}
                </span>
            ),
        },
        {
            name: t("Common.reinvist").toUpperCase(),
            cell: (row) => (
                <>
                    {
                        row.buttonsts && (
                            <Button onClick={Reinvist}>{t('Common.invest')}</Button>
                        )

                    }
                </>
            ),

        }
    ];
    const [state, setState] = useState({ data: [] });
    const { data } = state;

    useEffect(() => {
        let Data = tableData.data && tableData.data.map((data) => {
            return {
                classnameamount: style.credit,
                product_amount: data.product_amount,
                date: data.date,
                packageName: data.packageName,
                total_yield: data.total_yield,
                buttonsts: data.button_status
            }
        })
        setState((prev) => ({
            ...prev,
            data: Data
        }))
        
    }, [tableData, setState])


    const handlePageChange = (inPage) => {
        paginationHandler(inPage, tableData.perPage);
    };
    const handlePerRowsChange = (perPage, inPage) => {
        paginationHandler(inPage, perPage);
    };

    const Reinvist = () => {
        history.push('/upgrade_package')
    }
    return (
        <div>
            <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                // progressPending={ContentData.pending}
                progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                paginationTotalRows={tableData.totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noDataComponent={t("Common.noDataRecordsToDisplay")}
                responsive
            />
        </div>
    )
}

export default RoiTable