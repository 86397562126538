import React from 'react';
import style from './auth.module.scss';
import {useTranslation} from 'react-i18next';
const ForgotButton = ({submit,...props}) => {
    const {t} = useTranslation();
    return (
        <div className={style.loginButton}>
            <button className={`${style.loginFormBtn}`} onClick={submit} disabled={props.disabled || props.loader}>
                {t('Login.PlaceHolder.sendreq')}
                {
                    props.loader && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px",marginLeft:'5px', backgroundColor: "transparent" }}
                        />
                    )
                }
                </button>
        </div>
    );
};

export default ForgotButton;