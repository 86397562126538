import React, { useEffect } from 'react';
import {useSelector,useDispatch}  from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import style from './Common.module.scss';
import {changeCurr} from '../../../store/action';
import AppService from '../../../service/common/GetApi'
const CurrToggle = React.forwardRef(({ children, onClick }, ref) => (
    
    <span
      className={style.selectedCurr}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      {children}
     
    </span>
  )); 

function Currency() {
    const Curr = useSelector(state => state.curr);
    const dispatch  = useDispatch();

    //on change curency 
    const test = (value)=>{
        
        const service = new AppService();
        let formData = {
            currency : value.id
        }
        service.changecurrency(formData).then(res=>{
            if(res.status){
                localStorage.setItem('currency',value.code)
                dispatch(changeCurr(value.symbol_left,value.value,value.precision,value.id))
            }
        })
    }
    useEffect(()=>{
        if( localStorage.getItem('currency')){
            let defaultCurrency = Curr.Currecy.find((obj)=>obj.code == localStorage.getItem('currency'));;
       
        if(defaultCurrency){
            dispatch(changeCurr(defaultCurrency.symbol_left,defaultCurrency.value,defaultCurrency.precision,defaultCurrency.id))
        }
        else{
             Curr.Currecy.some(element=>{
            defaultCurrency = element
            return element.default
        })
        if(defaultCurrency){
            dispatch(changeCurr(defaultCurrency.symbol_left,defaultCurrency.value,defaultCurrency.precision,defaultCurrency.id))
        }

        }

        }
        
    },[Curr.Currecy,dispatch])
    return ( 
        <>   
            <div className={style.currContainer}>
                <Dropdown className={`${style.currButton}`}>
                    <Dropdown.Toggle as={CurrToggle}>
                        <span>{Curr.currentCurr}</span>
                      {  Curr.status  &&    <span className={style.caretIcon}></span> }
                    </Dropdown.Toggle>
                    {
            Curr.status  &&
                    <Dropdown.Menu className={`animate__animated animate__fadeInRight ${style.currencyDropDown}`}>
                    {
                            Curr.Currecy.map((value,index)=>{
                              
                                return <Dropdown.Item key={index} onClick={() => {
                                    test(value)
                                    }
                                } 
                                    className={style.dropdownItem}>
                                            <span>{value.symbol_left}</span>
                                            <span className="pl-1">{value.title}</span>
                                        </Dropdown.Item>
                            }) 
                        }
                    </Dropdown.Menu>
                        }
                </Dropdown>
            </div>
        
        </>
    )
}

export default Currency
