import React from 'react'
import { useTranslation } from "react-i18next";
//compoents
import Tabs from "react-responsive-tabs";
import style from "./profile.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import InvestmentTable from './InvestmentTable';
const stylees = {
    paddingTop: "10px",
    fontFamily: "system-ui",
};

function TableContent({
    tableData,
    paginationHandler,
    tabChangeHandler
}) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const { t } = useTranslation();
    const [tabs, setTabs] = useState([
        "investmentTable",
    ]);

    const getTabs = () => {
        return tabs.map((tab, index) => ({
            title: t("Common." + tab),
            getContent: () => getContent(tab),
            /* Optional parameters */
            key: tab,
            tabClassName: style.TabClass,
            panelClassName: style.TabPanel,
            // tabsWrapperClass : 'safdsadfads'
        }));
    };

    const getContent = (contentId) => {
        if (contentId === "investmentTable") {
            return (
                <div className={style.dataTable} style={stylees}>
                    <InvestmentTable
                        tableData={tableData}
                        paginationHandler={paginationHandler}
                    />
                </div>
            )
        }

    }
  return (
    <div className={style.summary}>
    <div
        className={`${currentTheme == "theme-dark"
                ? `${style.dark_mode_tabContent}`
                : `${style.tabContent}`
            }`}
    >
        <Tabs
            items={getTabs()}
            transform={true}
            transformWidth={734}
            onChange={tabChangeHandler}
            selectedTabKey="investmentTable"
        />
    </div>
</div>
  )
}

export default TableContent