import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Table } from "react-bootstrap";
import PayoutService from "../../../service/payout";
import { AlertMessage, FormInputControl, FormInputGroup } from "../common";
import style from "./payout.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ModalForms(props) {
  // const service = new PayoutService();
  const [state, setState] = useState({
    payout_fee: 0,
    particular: [],
    amount: "",
    IsError: false,
    validated: false,
    notifyMessage: "",
    notyfiType: "",
    header: "",
    balance: 0,
    password: "",
    type: "",
    fee: 0,
    FormError: {
      amount: "",
      password: "",
    },
    loader: false,
  });
  const { currentTheme } = useSelector((state) => state.Theme);
  //get the current currency
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();
  useEffect(() => {
    const service = new PayoutService();
    service.GetPayoutRequest().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          payout_fee: res.data.amount.payout_fee,
          default_currency: res.data.amount.defaultCurrencyCode,
          particular: res.data.particulars,
          amount: (
            res.data.amount.available_max_payout * Currency.value
          ).toFixed(Currency.precision),
          balance: (res.data.amount.balance * Currency.value).toFixed(
            Currency.precision
          ),
          type: res.data.amount.type,
          fee: res.data.amount.fee,
        }));
      }
    });
  }, [Currency]);
  const {
    amount,
    FormError,
    IsError,
    notifyMessage,
    notyfiType,
    header,
    password,
    balance,
    payout_fee,
    particular,
  } = state;

  const [fees, setFees] = useState();
  //balance amount conversion
  //binding the value on change
  const changeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    FormError.password = "";
    FormError.amount = "";

    setState((prev) => ({
      ...prev,
      validated: false,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (state.type === "percentage") {
      setFees((state.amount * state.fee) / 100);
    } else {
      setFees(state.fee);
    }
  }, [state.amount]);

  // const balance = (balance*Currency.value).toFixed(Currency.precision);
  //on submit the form value
  const submitForm = (e) => {
    e.preventDefault();
    let valid = true;
    valid = validation("amount") ?? false;
    valid = validation("password") ?? false;
    if (valid) {
      let data = {};
      data["transaction_password"] = password;
      data["payout_amount"] = amount;
      data["withdraw"] = true;
      // const data = new FormData();
      // data.append("transaction_password", password);
      // data.append("payout_amount", amount);
      // data.append("withdraw", true);
      const service = new PayoutService();
      setState((prev) => ({
        ...prev,
        loader: true,
      }));
      service.PayoutRequest(data).then((res) => {
        console.log("res payout ====== ", res);
        if (res.status) {
          // toast.success("payout_request_sent_successfully")
          setState((prev) => ({
            ...prev,
            FormError,
            amount: "",
            password: "",
            validated: !valid,
            IsError: true,
            notifyMessage: res.data.message,
            notyfiType: "success",
            header: t("Common.Success"),
            loader: false,
          }));
          props.submitSuccess();
        } else {
          let message = "";
          if (res.error.code === 1019) {
            message = t("validation.kycNotverified");
          } else if (res.error.code === 1015) {
            FormError.password = "validation.invalidTransactionPassword";
            message = t("validation.invalidTransactionPassword");
          } else if (res.error.code == 1004) {
            FormError.password = "validation.invalidTransactionPassword";
            message = t("validation.invalidTransactionPassword");
          } else if (res.error.code == 1027) {
            message = t(
              `payout amount should be greaterthan or equal to ${
                Currency.currentCurr
              } ${
                state.particular[5].amount *
                Currency.value.toFixed(Currency.precision)
              }`
            );
          } else if (res.error.code == 1028) {
            message = t(
              `payout amount should be lessthan or equal to ${
                Currency.currentCurr
              } ${
                state.particular[5].amount *
                Currency.value.toFixed(Currency.precision)
              }`
            );
          } else if(res.error.code ==1078){
            message = t("validation.walletAddressValidation");
          }else {
            message = res.error.description;
          }
          setState((prev) => ({
            ...prev,
            validated: !valid,
            IsError: true,
            notifyMessage: message,
            notyfiType: "danger",
            header: t("Common.error"),
            loader: false,
          }));
        }
      });
    } else {
      setState((prev) => ({
        ...prev,
        FormError,
        validated: !valid,
        IsError: true,
        notyfiType: "danger",
        header: t("Common.error"),
        loader: false,
      }));
    }
  };

  //validation
  const validation = (name) => {
    // const FormError = FormError;
    const values = state;
    let valid = true;
    let error = "";
    let fee = 0;
    if (values.type === "percentage") {
      fee = (Number(values.amount) * Number(values.payout_fee)) / 100;
    } else {
      fee = Number(values.payout_fee);
    }

    switch (name) {
      case "amount":
        valid = false;
        if (values.amount.length === 0) {
          FormError.amount = t("validation.insufficientBalance");
          error = t("validation.insufficientBalance");
        } else if (values.amount <= 0) {
          FormError.amount = t("validation.insufficientBalance");
          error = t("validation.insufficientBalance");
        } else if (
          parseInt(values.amount) + parseInt(fee * Currency.value) >
          balance * Currency.value
        ) {
          //  else if( values.amount values.fee )
          FormError.amount = t("validation.insufficientBalance");
          error = t("validation.insufficientBalance");
        } else {
          FormError.amount = "";
          valid = true;
        }
        break;
      case "password":
        if (values.password.length === 0) {
          FormError.password = t("validation.transactionPassword");
          valid = false;
          error = t("validation.transactionPassword");
        }
        break;
      default:
        valid = true;
    }
    if (!valid && error) {
      setState((prev) => ({
        ...prev,
        notifyMessage: error,
      }));
    }
    setState((prev) => ({
      ...prev,
      FormError,
      validated: !valid,
    }));
    return valid;
  };
  //on dissmissed notify
  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      IsError: false,
      notifyMessage: "",
    }));
    if (notyfiType === "success") {
      props.onClose();
    }
  };

  return (
    <Form onSubmit={submitForm} noValidate>
      {/* <p className="pb-2">{"Default Currency : " + (state.default_currency)}</p> */}
      <FormInputGroup
        label={t("payout.withdrawalAmount")}
        groupId="withdrawalAmount"
        required={true}
        disabled={true}
        isPrepend={true}
        error={FormError.amount}
        inputProps={{
          name: "amount",
          type: "number",
          placeholder: t("Common.amount"),
          value: amount,
          onChange: changeHandler,
        }}
      />
      <FormInputControl
        label={t("Common.transactionPassword")}
        required={true}
        error={{
          error: FormError.password,
        }}
        inputProps={{
          name: "password",
          type: "password",
          value: password,
          placeholder: t("Common.transactionPassword"),
          onChange: changeHandler,
        }}
      />
      <Button
        variant="info"
        type="submit"
        className={style.button}
        disabled={state.loader}
      >
        {state.loader ? (
          <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />
        ) : (
          ""
        )}
        {t("Button.withDraw")}
      </Button>
      {IsError && (
        <AlertMessage
          message={notifyMessage}
          dismiss={notifyDissmissed}
          type={notyfiType}
          header={header}
          show={IsError}
        />
      )}
      {/* <Col md={12}>{t('payout.additionalAmount',{amount:`${Currency.currentCurr} ${(payout_fee*Currency.value).toFixed(Currency.precision)} `})}</Col> */}
      {state.type === "percentage" ? (
        <Col md={12}>
          {t("payout.additionalAmount", {
            amount: `${Currency.currentCurr} ${parseFloat(fees).toFixed(2)}`,
          })}
        </Col>
      ) : (
        <Col md={12}>
          {t("payout.additionalAmount", {
            amount: `${Currency.currentCurr} ${parseFloat(fees).toFixed(2)}`,
          })}
        </Col>
      )}

      <Table responsive>
        <thead
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_tableBody}`
              : `${style.tableBody}`
          }`}
        >
          <tr>
            <th>{t("Common.perticurals")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {particular.map((items, index) => {
            if (items.amount * Currency.value >= 0 && index !== 8) {
              return (
                <tr key={index}>
                  <td>{items.key}</td>
                  <td>
                    {Currency.currentCurr}{" "}
                    {(items.amount * Currency.value).toFixed(
                      Currency.precision
                    )}
                  </td>
                </tr>
              );
            } else {
              return (
                <tr key={index}>
                  <td>{items.key}</td>
                  <td>{items.amount}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
    </Form>
  );
}

export default ModalForms;
