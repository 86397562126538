import React from 'react'
import Helmet from 'react-helmet';
import companyName from '../../../store/mobxStore/companyName';
import PageTitle from '../../components/common/pageTitle';
import { useTranslation } from 'react-i18next';
import ReinjectContent from '../../components/Reinject/ReinjectContent';
function Reinject() {
    const { t } = useTranslation();
    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.reinject')}</title>
            </Helmet>
            <PageTitle
                title="reinject"
                buttonOn={false}
            />
            <ReinjectContent/>
        </div>
    )
}

export default Reinject