import React from 'react'
import { Helmet } from 'react-helmet'
import { PageTitle } from '../../components/common'
import companyName from '../../../store/mobxStore/companyName'
import { useTranslation } from 'react-i18next'
import DocumentContent from '../../components/Resources/DocumentContent'
function Documents() {
  const {t}=useTranslation();
  return (
    <div className="h-100">
      <Helmet>
        <title>{companyName.companyName} | {t('Sidemenu.Documents')}</title>
      </Helmet>
      <PageTitle
        title="Documents"
        buttonOn={false}
      />
      <DocumentContent />
    </div>
  )
}

export default Documents