import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
//import CartContent from '../../components/shopping/CartContent';
import Spinner from '../../shared/Spinner';
import shoppingService from '../../../service/shopping';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
//import { useHistory } from 'react-router';
import RepurchasePackage from '../../components/shopping/RepurchasePackage';
import { AlertBs } from '../../components/common';



function PackageDetails(props) {
    let service = new shoppingService();
    const [state, setState] = useState({
        data: [],
        loader: true,
        cart: {},
        ProductId: '',
        alert: {
            show: false,
            varient: '',
            message: ''
        },

    })

    const buttonGroups = [
    ];
    useEffect(() => {
        const { id } = props.match.params;
        service.getRepurchasePackage(id)
            .then(res => {
                if (res.status) {
                    setState(prevState => ({
                        ...prevState,
                        data: res.data,
                        loader: false,
                        cart: res.data.cart.quantity,
                        ProductId: id
                    }));
                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout');
                    } else if (res.error.code === 1049) {
                        props.history.push({
                            pathname: '/reinvest',
                            state: {
                                error: true,
                                message: 'invalidProductId'
                            }
                        });
                    } else if (res.error.code === 1057) {
                        props.history.push({
                            pathname: '/dashboard',
                            state: {
                                error: true,
                                message: 'permissionDenied'
                            }
                        })
                    }
                }
            })



    }, []);
    // const showRepurchaseReport = () => {
    //     alert('show showRepurchaseReport');
    // }


    //update cart
    const updateCart = (quantity) => {
        if (state.cart > 0) {
            service.updateCart(state.data.cart.rowid, quantity).then(res => {
                if (res.status) {
                    props.history.push({
                        pathname: '/reinvest',
                        state: {
                            success: true,
                            update: true,
                            productName: state.data.product.product_name
                        }
                    });
                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout')
                    }
                }
            })
        } else {
            const formData = {
                product_id: state.ProductId,
                product_qty: quantity
            }
            service.addToCart(formData).then(res => {
                if (res.status) {
                    props.history.push({
                        pathname: '/reinvest',
                        state: {
                            success: true,
                            update: false,
                            productName: state.data.product.product_name
                        }
                    });
                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout')
                    }
                    if (res.error.code === 1075) {
                        setState((prev) => ({
                            ...prev,
                            alert: {
                                ...prev.alert,
                                show: true,
                                varient: 'danger',
                                message: t(`validation.qunatitycantbegreatre1`)
                            }
                        }))
                    }

                }
            })
        }
    }

    const closeAlert = () => {
        setState(prevState => ({
            ...prevState,
            alert: {
                ...prevState.alert,
                show: false,
                varient: '',
                message: ''
            }
        }))
    }


    const { t } = props;
    if (state.loader) {
        return <Spinner />
    }

    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.product_details')}</title>
            </Helmet>
            <PageTitle title="product_details" buttonOn={false} group={buttonGroups} />
            {/* <AlertBs {...state.alert} close={closeAlert}/> */}
            <RepurchasePackage
                {...state}
                updateCart={updateCart}
                {...state.data}

                close={closeAlert}
            />
        </div>


    );

}
export default withTranslation()(withRouter(PackageDetails)); 