import React from 'react'
import { Card } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { useEffect, useState } from 'react'
import TutorialService from '../../../service/Resources/Tutorial'
import Filter from './Filter'
import style from './ewallet.module.scss'
import { IMG_URL } from '../../../configuration/apiconfig'
import { Button } from 'react-bootstrap'
function Tutorialcontent() {
  const [state, setstate] = useState({
    data: [],
    Languages: [],
    selectedLanguage: '',
    searchloader: false,
    resetloader: false,
    searchvalue: '',
    reset: false,
    Description: ""
  })

  const [showFulltext, setshowFulltext] = useState(false)


  const service = new TutorialService();
  useEffect(() => {
    getVideos(state.searchvalue, state.selectedLanguage);
  }, [state.selectedLanguage])


  const getDescription = () => {
    state.data.map((items, index) => ({

    }))
  }

  const getVideos = () => {
    const selectedLanguage = state.selectedLanguage
    const searchvalue = state.searchvalue
    service.getTutorialVideos(selectedLanguage, searchvalue).then((res) => {
      if (res.status) {
        setstate((prev) => ({
          ...prev,
          data: res.data,
          Languages: res.languages
        }))
      }
    })
  }

  const selectLanguage = (e) => {
    const { name, value } = e.target
    setstate((prev) => ({
      ...prev,
      selectedLanguage: value,
    }))
  }


  const searchVideosHandler = (e) => {
    const { name, value } = e.target
    setstate((prev) => ({
      ...prev,
      searchvalue: value,
    }))

  }




  const searchVideos = () => {
    getVideos(state.selectedLanguage, state.searchvalue);
    setstate((prev) => ({
      ...prev,
      searchloader: true,
      reset: false
    }))
    setTimeout(() => {
      setstate((prev) => ({
        ...prev,
        searchloader: false,
        reset: false
      }));
    }, 1000);
  }

  const Resetvideos = () => {
    setstate({
      ...state,
      searchvalue: '',
      resetloader: true,
      reset: true
    })
    getVideos(state.selectedLanguage, '');

    setTimeout(() => {
      setstate((prev) => ({
        ...prev,
        resetloader: false,
        reset: false
      }));
    }, 1000);
  };

  useEffect(() => {
    getVideos(state.selectedLanguage, "")
  }, [state.reset && (state.searchvalue)])
  const descriptionshowing=(index)=>{
    if(index==index){
      setshowFulltext(true)
    }else{
      setshowFulltext(false)
    }
  }

  return (
    <div className={style.tutorialMain}>
      <Filter
        {...state}
        Languages={state.Languages}
        selectLanguage={selectLanguage}
        selectedLanguage={state.selectedLanguage}
        searchVideos={searchVideos}
        Resetvideos={Resetvideos}
        searchvalue={state.searchvalue}
        searchVideosHandler={searchVideosHandler}
      />

      {
        state.data.length === 0 ?
          <div className={`${style.NodataImage}`}>
            <Card>
              <Card.Body>
                <center>
                  <img
                    src={IMG_URL + "logos/no-datas-found.png"}
                    alt="nodata"
                    style={{ width: "50%" }}
                  />
                </center>
              </Card.Body>
            </Card>

          </div>
          :
          <Row
            className={style.cotainer}
            // style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(32%, 1fr))', gap: '0px' }}
            >

            {
              state.data.map((item, index) => {
                return (
                  <Col md={12} lg={12} xl={12}>
                    <Card>
                      <Card.Body>

                        <div class='tutorial_video_box'>
                          {/* <div style={{ display: "flex", justifyContent: "center" }}> */}

                          {/* <iframe src={item.file}
                            frameborder='0'
                            allow='autoplay; encrypted-media'
                            allowfullscreen
                            title='video'
                          /> */}
                          {/* <video  controls >
                            <source src={item.file} type="video/mp4"  width='100%' height='100%'/>
                          </video> */}
                          <ReactPlayer
                            url={item.file}
                            // url='https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761'
                            controls={true}
                            playing={false}
                            loop={true}
                            style={{ width: "80%", height: "50vh" }}
                            
                          />
                          {/* </div> */}
                        </div>


                      </Card.Body>
                      <Card.Footer className={!item.description&&(style.footerlength)}>
                        <h4><b>{item.title}</b></h4>
                        <p>{item.description}</p>

                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>

      }

    </div>


  )
}

export default Tutorialcontent
