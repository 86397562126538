import React from 'react'
import style from './cart.module.scss';
import { Table, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function CartTable(props) {
    const { t } = useTranslation();
    const currency = useSelector(state => state.curr);
    // const totalAmount = () => {
    //     let totalPrice = props.cart.reduce(function (accumulator, item) {
    //         return accumulator + (item.price * item.qty);
    //     }, 0);
    //     return (totalPrice * currency.value).toFixed(currency.precision)
    // }
    const priceConversion = (amount) => {
        return (parseFloat(amount) * currency.value).toFixed(currency.precision);
    }
    const subTotal = (price, qty) => {
        return (parseInt(price) * qty * currency.value).toFixed(currency.precision)
    }
    return (
        <Card className={`${style.cartTable}`}>
            <Card.Body className={style.cardBody}>
                <div className={`${style.cartTableBody}`}>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                {/* <th>#</th> */}
                                {/* <th>{t('Common.product')}</th> */}
                                <th>{t('Common.price')}</th>
                                {/* <th>{t('Common.quantity')}</th>
                            <th>{t('Common.subTotal')}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {/* <tr >
                        <td>{currency.currentCurr} {priceConversion(props.amount)}</td>
                        </tr> */}
                            
                          {
                            props.cart.map((item,index)=>(
                                <tr key={index}>
                                    {/* <td>{index+1}</td>
                                    <td>{item.name}</td> */}
                                    <td>{currency.currentCurr} {priceConversion(item.price)}</td>
                                    {/* <td>{item.qty}</td> */}
                                    {/* <td><Button>Remove</Button></td> */}
                                      
                                </tr>
                            ))
                        }
                            {/* <tr>
                                <td colSpan="4" style={{ textAlign: 'right' }}>
                                    <b>{t('Common.total')}</b>
                                </td>
                                <td>
                                    {currency.currentCurr} {totalAmount()}
                                </td>
                            </tr> */}
                        </tbody>
                    </Table>
                </div>
                <Link to="/checkOut">
                    <Button className={`${style.checkOutButton}`}>
                        <i className={`fa fa-shopping-cart`}></i>
                        {t('Button.checkout')}
                    </Button>
                </Link>
            </Card.Body>
        </Card>
    )
}

export default CartTable
