import React from 'react';
import style from './Common.module.scss';
import {Form,InputGroup} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

function FormInputGroup(props) {
    const {t} = useTranslation();
    const Currency = useSelector(state => state.curr);
    
    return (
        <Form.Group>
            <Form.Label htmlFor={props.groupId} className={props.isRequired&&style.required}>
                {t(props.label)}
            </Form.Label>
            <InputGroup className="mb-2">
                {
                    props.isPrepend &&
                    <InputGroup.Prepend className={style.prepend}>
                        <InputGroup.Text>{Currency.currentCurr}</InputGroup.Text>
                    </InputGroup.Prepend>
                }
                <Form.Control 
                    id={props.groupId} 
                    // placeholder={t(props.label)} 
                    disabled={props.disabled}
                    // value={ props.value && (props.value*Currency.value).toFixed(Currency.precision)}
                    // as={props.as}
                    // rows={10}
                    // type={props.type}
                    // onChange={props.onChange}
                    // name={props.name}
                    required={props.isRequired}
                    className={`
                        ${style.FormField}
                        ${props.error ?`${style.formsHasError}form-control is-invalid`: ""}
                        `}
                    {...props.inputProps}
                />
                {/* {
                    props.error &&
                    <div className={style.invalidFeedBack}>{props.error}</div>
                    // <Form.Control.Feedback type="invalid">{t('validation:'+error)}</Form.Control.Feedback>
                } */}
            </InputGroup>
            {
                props.error&&
                <Form.Text className={`
                    ${props.error
                        ? style.isInvalid
                        : "d-none"}
                    `}>
                        {t(props.error)}
                </Form.Text>
            }
        </Form.Group>
    );        
}
export default FormInputGroup;