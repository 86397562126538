import React from 'react'
import ProductsService from '../../../service/Products/Product'
import { useState, useEffect } from 'react';
import Charts from './Charts';
import { Prev } from 'react-bootstrap/esm/PageItem';
import TabContent from './TabContent';
function Content() {
    const service = new ProductsService();
    const [ChartData, setChartData] = useState({})
    const [state, setstate] = useState({
        RoiTable: {
            totalRows: 0,
            data: [],
            pending: true,
            perPage: 10,
            inPage: 1
        }
    })
    const getGraphData = () => {
        service.getGraphData().then((res) => {

            if (res.status) {
                setChartData(res.data)
            }
        })
    }

    const LoadTableData = (
        inPage = state.RoiTable.inPage,
        perPage = state.RoiTable.perPage
    ) => {
        let start = (inPage - 1) * perPage
        service.getRoitableDAta(start, perPage).then((res) => {
            if (res.status) {
                setstate((prev) => ({
                    ...prev,
                    RoiTable: {
                        ...prev.RoiTable,
                        totalRows: res.data.count,
                        data: res.data.allPackageList
                    }
                }))
            }
        })
    }

    const changeRoitable = (inPage, perPage) => {
        setstate((prev) => ({
            ...prev,
            RoiTable: {
                ...prev.RoiTable,
                inPage: inPage,
                perPage: perPage
            }
        }))

        LoadTableData(inPage, perPage)

    }

    const tabChangeHandler = () => {
        setstate((prev) => ({
            ...prev,
            RoiTable: {
                ...prev.RoiTable,
                perPage: 10,
                inPage: 1
            }
        }))
        LoadTableData()
    }

    useEffect(() => {
        getGraphData()
        LoadTableData()
    }, [])



    return (
        <div>
            {
                ChartData && (
                    <Charts
                        data={ChartData}
                    />
                )
            }

            <TabContent
                tableData={state.RoiTable}
                paginationHandler={changeRoitable}
                tabChangeHandler={tabChangeHandler}

            />

        </div>
    )
}

export default Content