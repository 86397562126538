import React from 'react'
import { Helmet } from 'react-helmet'
import companyName from '../../../store/mobxStore/companyName'
import { PageTitle } from '../../components/common'
import { useTranslation } from 'react-i18next'
import Tutorialcontent from '../../components/Resources/Tutorialcontent'
import style from '../../components/Resources/ewallet.module.scss'
function Tutorials() {
    const { t } = useTranslation();
    return (
        <div className='h-100'>
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.tutorials')}</title>
            </Helmet>
            <PageTitle
                title="tutorials"
                buttonOn={false}
            />
            <div className={style.MainContainer}>
                <Tutorialcontent />
            </div>

        </div>
    )
}

export default Tutorials