import React from 'react'
import { Helmet } from 'react-helmet'
import { PageTitle } from '../../components/common'
import companyName from '../../../store/mobxStore/companyName'
import { useTranslation } from 'react-i18next'
import Content from '../../components/Product/Content'
import style from '../../components/Product/ewallet.module.scss'
function Products() {
  const { t } = useTranslation();
  return (
    <div className="h-100">
      <Helmet>
        <title>{companyName.companyName} | {t('Sidemenu.Products')}</title>
      </Helmet>
      <PageTitle
        title="Products"
        buttonOn={false}
      />
      <div className={style.MainContainer}>
        <Content/>
      </div>
    </div>
  )
}

export default Products