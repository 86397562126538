import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./profile.module.scss";
import { useHistory } from "react-router";

function InvestmentTable({ tableData, paginationHandler }) {
    const { t } = useTranslation();
    const Currency = useSelector((state) => state.curr);
    const history = useHistory();
    const columns = [
        {
            name: t("Common.pv").toUpperCase(),
            selector: (row) => row.pv,
            // cell: (row) => (
            //     <span className={``}>
            //         {Currency.currentCurr}{" "}
            //         {(row.product_amount * Currency.value).toFixed(Currency.precision)}
            //     </span>
            // ),
        },
        {
            name: t("Common.date").toUpperCase(),
            selector: (row) => row.date,
        },
        
        {
            name: t("Common.totalamount").toUpperCase(),
            selector: (row) => row.amount,
            cell: (row) => (
                <span className={``}>
                    {Currency.currentCurr}{" "}
                    {(row.amount * Currency.value).toFixed(Currency.precision)}
                </span>
            ),
        },
        
    ];
    const [state, setState] = useState({ data: [] });
    const { data } = state;

    useEffect(() => {
        let Data = tableData.data && tableData.data.map((data) => {
            return {
                classnameamount: style.credit,
                pv: data.pv,
                date: data.date,
                amount: data.amount,
            }
        })
        setState((prev) => ({
            ...prev,
            data: Data
        }))
        
    }, [tableData, setState])


    const handlePageChange = (inPage) => {
        paginationHandler(inPage, tableData.perPage);
    };
    const handlePerRowsChange = (perPage, inPage) => {
        paginationHandler(inPage, perPage);
    };

    
    return (
        <div>
            <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                // progressPending={ContentData.pending}
                progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                paginationTotalRows={tableData.totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noDataComponent={t("Common.noDataRecordsToDisplay")}
                responsive
            />
        </div>
    )
}

export default InvestmentTable