import React from 'react'
import style from './ewallet.module.scss'
import { Button, Form, Card, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
function Filter(props) {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <Card className={style.titlepanel}>
      <Card.Body className={style.panelBody}>
        <Row>
          <Col md={6} sm={12}>
            <div className={style.searchRight}>
              <div className={style.paddingSmall} style={{ width: '20em' }}>
                <Form.Group className={style.formGroup}>
                  <Form.Control
                    as="select"
                    className={style.dropDownSelect}
                    onChange={props.selectLanguage}
                    value={props.selectedLanguage}
                  >
                    <option value="">{t("Common.selectLanguage")}</option>
                    {props &&
                      props.Languages.map((items) => (
                        <option value={items.id} key={items}>
                          {t(`Common.${items.name}`)}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </div>

            </div>
          </Col>
          <Col md={6} sm={12}>

            <div className={style.searchcontent}>
              <Form.Group className={style.formGroup}>
                <Form.Control
                  type='text'
                  className={style.dropDownSelect}
                  onChange={props.searchVideosHandler}
                  value={props.searchvalue}
                  placeholder={t('Common.enterTitle')}
                />

                <div style={{ display: 'flex' }}>

                  <Button
                    className='ml-2'
                    disabled={props.searchloader}
                    onClick={props.searchVideos}
                  >
                    {props.searchloader ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      ""
                    )}
                    {t("Button.search")}
                  </Button>
                  <Button
                    className='ml-2'
                    disabled={props.resetloader}
                    onClick={props.Resetvideos}
                  >
                    {props.resetloader ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      ""
                    )}

                    {t("Button.reset")}
                  </Button>
                </div>




              </Form.Group>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Filter