import React from 'react'
import Spinner from '../../shared/Spinner';
import { useState, useEffect } from 'react';
import style from './ewallet.module.scss'
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { IMG_URL } from '../../../configuration/apiconfig';
import { useHistory } from 'react-router';
import DocumentsService from '../../../service/Resources/Documents';
import Doc from '../../../assets/images/document(1).png'
import Filter from './Filter';
function DocumentContent() {
    const service = new DocumentsService();
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState([])
    const { currentTheme } = useSelector((state) => state.Theme)
    const [data, setdata] = useState({
        selectedLan: '',
        Languages: [],
        searchloader: false,
        resetloader: false,
        searchvalue: '',
        reset: false
    })
    const history = useHistory();
    // useEffect(()=>{
    //     getDocumnet();
    // },[])
    useEffect(() => {
        setLoading(true);
        getDocumnet();
    }, [data.selectedLan]);


    const getDocumnet = () => {
        const selectedLan = data.selectedLan
        const keyword = data.searchvalue
        service.getDocuments(selectedLan, keyword).then((res) => {
            if (res.status) {
                setState(res.data);
                setLoading(false);
                setdata((prev) => ({
                    ...prev,
                    Languages: res.languages
                }))
            } else {
                setLoading(false);
                if (res.error.code === 1002) {
                    history.push("/logout");
                } else if (res.error.code === 1057) {
                    history.push({
                        pathname: "/dashboard",
                        state: {
                            error: true,
                            message: "permissionDenied",
                        },
                    });
                }
            }
        });
    }
    const selectLanguage = (e) => {
        const { name, value } = e.target
        setdata((prev) => ({
            ...prev,
            selectedLan: value
        }))
    }

    const searchDocumentHandler = (e) => {
        const { name, value } = e.target
        setdata((prev) => ({
            ...prev,
            searchvalue: value
        }))

    }

    useEffect(() => {
        getDocumnet(data.selectedLan, "")
    }, [data.reset && (data.searchvalue)])



    const searchDocument = () => {
        getDocumnet(state.selectedLanguage, state.searchvalue);
        setdata((prev) => ({
            ...prev,
            searchloader: true,
            reset: false
        }))
    }


    setTimeout(() => {
        setdata((prev) => ({
            ...prev,
            searchloader: false,
        }));
    }, 1000);

    const ResetDocument = () => {
        setdata({
            ...data,
            searchvalue: '',
            resetloader: true,
            reset: true
        })
        getDocumnet(data.selectedLan, data.searchvalue);

        setTimeout(() => {
            setdata((prev) => ({
                ...prev,
                resetloader: false,
                reset: false
            }));
        }, 1000);
    };



    return loading ? (
        <Spinner
        />
    ) : (

        <>

            <div className={style.downloadConatiner}>
                <Filter
                    {...data}
                    Languages={data.Languages}
                    selectLanguage={selectLanguage}
                    selectedLanguage={data.selectedLan}
                    searchVideos={searchDocument}
                    Resetvideos={ResetDocument}
                    searchvalue={data.searchvalue}
                    searchVideosHandler={searchDocumentHandler}
                />
                <div className={style.userDowmloadSection}>
                    {state && state.length > 0 ? (
                        state.map((items, index) => (
                            <>

                                <div
                                    className={`${currentTheme == "theme-dark"
                                        ? `${style.dark_mode_content}`
                                        : `${style.content_document}`
                                        }`}
                                    key={index}
                                >
                                    <div>
                                        <a
                                            href={items.file}
                                            target="_blank"
                                            className={style.linkcoloor}>
                                            <img
                                                src={Doc}
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                    <div className={style.snddiv}>
                                        <div><h3>{items.title}</h3></div>
                                        <div>{items.uploaded_date}</div>
                                        <div><p>{items.description}</p></div>
                                    </div>
                                </div>

                            </>
                        ))
                    ) : (
                        <>
                            <div className={`${style.NodataImage}`}>
                                <center>
                                    <img
                                        src={IMG_URL + "logos/no-datas-found.png"}
                                        alt="nodata"
                                        style={{ width: "25%" }}
                                    />
                                </center>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>

    );
}

export default DocumentContent