import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import style from './registration.module.scss';
import { Paneltilte, Buttons } from './';
import FormsControl from './FormsControl';
import { useTranslation } from 'react-i18next';
function SponsorPackage(props) {
    const {t}=useTranslation();
    const [data, setData] = useState([])
    //change handler
    const changehandler = (e) => {
        props.ChangeHandler(e, 'sponsor')
    }
    const Currency = useSelector(state => state.curr);
    useEffect(() => {
        let newData = props?.data && props.data.map(object => {
            if (object.code === 'position') {
                let newOption = object.options.map((option) => {
                    return {
                        ...option,
                        code: ('profile.' + option.code)
                    }
                })
                return {
                    ...object,
                    options: newOption
                }
            }
            // else if(object.code==="product"){
            //     let newOption= object.options.map((option)=>{
            //         let amount =(option.productValue * Currency.value).toFixed(Currency.precision);
            //         return{
            //             ...option,
            //             code : option.code+' ( '+Currency.currentCurr+''+amount+' )'
            //         }
            //     })
            //     return{
            //         ...object,
            //         options : newOption
            //     }
            // }
            else {
                return object
            }
        });
        setData(newData);
    }, [props.data, Currency]);
    return (
        <div className={style.SponsorPackage}>
            <Paneltilte
                tilte={props.title.code}
            />
            <Form onSubmit={(e) => { props.submit(e, "", 'sponsor') }}>

                {
                    data.map((field, index) => {
                        return <FormsControl
                            key={index}
                            data={field}
                            change={changehandler}
                            blurhandler={props.blurhandler}
                        />
                    })
                }

                {/* <div style={{ border: 'dashed', borderWidth: 'thin', borderColor: '#5fb867', backgroundColor: '#d0ffc7' }}>
                    <p style={{ textAlign: 'center' }}>
                        <small>{t('validation.productvaluebetween200and10000')} </small>
                    </p>
                </div> */}
                <Buttons
                    prev={false}
                />
            </Form>
        </div>
    )
}

export default SponsorPackage
