import React, { useState } from "react";
import SmartWizard from "../common/SmartWizard";
import { Card } from "react-bootstrap";
// import style from './cart.module.scss';
import { useTranslation } from "react-i18next";
import { Packages, ContactInfo, Summary, Payments } from "./";
function CheckoutWizard(props) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    activePage: 0,
  });
  //change stepper change next
  const nextStep = () => {
    setState((prev) => ({
      ...prev,
      activePage: state.activePage + 1,
    }));
  };
  //previous steps
  const prevStep = () => {
    setState((prev) => ({
      ...prev,
      activePage: state.activePage - 1,
    }));
  };
  var steps = [
    {
      name: t("Common.package"),
      Components: Packages,
      props: {
        cart: props.cart,
        updateCart: props.updateCart,
        next: nextStep,
      },
    },
    // {
    //   name: t("registration.title.contactInformation"),
    //   Components: ContactInfo,
    //   props: {
    //     // ...props,
    //     address: props.address,
    //     removeAddress: props.removeAddress,
    //     modalToogle: props.modalToogle,
    //     next: nextStep,
    //     prev: prevStep,
    //     setDefault: props.setDefault,
    //   },
    // },
    // {
    //   name: t("Common.orderSummary"),
    //   Components: Summary,
    //   props: {
    //     data: props.cart,
    //     prev: prevStep,
    //     next: nextStep,
    //     FooterBtn: true,
    //   },
    // },
    {
      name: t("Common.paymentMethods"),
      Components: Payments,
      props: {
        amount: props.paymentAmount,
        methods: props.paymentsMethods,
        paymentMethodChange: props.tabChange,
        prevButtonClick: prevStep,
        ewallet: props.purchaseWallet,
        submit: props.submitForm,
        payment_type: "repurchase",
        epin: props.epin,
        stripeError: props.stripeError,
        loader: props.loading
      },
    },
  ];

  return (
    <div>
      <Card>
        <Card.Body>
          <SmartWizard steps={steps} activeTab={state.activePage} />
          {/* <Payments
            amount={props.paymentAmount}
            methods={props.paymentsMethods}
            paymentMethodChange={props.tabChange}
            prevButtonClick={prevStep}
            ewallet={props.purchaseWallet}
            submit={props.submitForm}
            payment_type="repurchase"
            epin={props.epin}
            stripeError={props.stripeError}
            loader={props.loading}
          /> */}
        </Card.Body>
      </Card>
    </div>
  );
}

export default CheckoutWizard;
