import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Image, Alert } from "react-bootstrap";
import style from "./payment.module.scss";
import Payments from "../../../service/common/PaymentApi";
import { useSelector } from "react-redux"

function CryptoPayment(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new Payments();
  const { t } = useTranslation();
  const [state, setState] = useState({
    selectedFile: null,
    url: "",
    accoundDetails: "",
    enableFinish: false,
    showAlert: false,
    alertType: "",
    AlertMessage: "",
    imageUploaded: false,
    loader: false
  });
  useEffect(() => {
    const accoundService = new Payments();
    accoundService.WalletDetails().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          accoundDetails: res.data.wallet_address,
        }));
      }
    });
  }, []);

  //file change event
  const onFileChange = (event) => {
    // Update the state
    if (event.target.files[0]) {
      setState((prev) => ({
        ...prev,
        selectedFile: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
      }));
    }
  };
  //upload image
  const uploadImgae = () => {
    const formData = new FormData();
    formData.append("file", state.selectedFile);
    formData.append("user_name", props.userName ?? "");
    formData.append("type", props.type ?? "subscription_renewal");
    if (window.location.pathname.includes('/replica_register')) {
      service.upload_Crypto_repllicaReciept(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            enableFinish: true,
            showAlert: true,
            alertType: "success",
            AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
            imageUploaded: true,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            enableFinish: false,
            showAlert: true,
            alertType: "danger",
            AlertMessage: t("Common.error_file_upload"),
          }));
        }
      });
    } else {
      service.upload_crypto_reciept(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            enableFinish: true,
            showAlert: true,
            alertType: "success",
            AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
            imageUploaded: true,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            enableFinish: false,
            showAlert: true,
            alertType: "danger",
            AlertMessage: t("Common.error_file_upload"),
          }));
        }
      });
    }

  };

  //remove image
  const removeImage = () => {
    setState((prev) => ({
      ...prev,
      selectedFile: "",
      url: "",
      enableFinish: false,
      showAlert: false,
    }));
    if (state.imageUploaded) {
      const username = {
        user_name: props.userName,
      };
      service.removeCryptoImage(username).then((res) => { });
    }
  };
  return (
    <div className={style.MianContent}>
      <Alert show={state.showAlert} variant={state.alertType} dismissible>
        {state.AlertMessage}
      </Alert>
      <Form
        onSubmit={(e) => {
          setState((prev) => ({
            ...prev,
            loader: true,
          }));
          window.location.pathname.includes("/register") ||
            window.location.pathname.includes("/replica_register")
            ? props.submit(e, "", "payment")
            : props.submit(e, "");
        }}
      >

        <Form.Group>
        <label>{t('Common.walletdetails')}</label>
          <div style={{fontSize:'small',padding:'2px',border:'dotted',borderColor:'#8080807a',background:'#6bce3e3d'}}>
            <span style={{color:'black'}}>{t('Common.usdt')}&nbsp;:&nbsp;<strong style={{color:'black'}}>0x5ba88c4852dd4774788e3d522c7a2ae78c898cd1</strong></span><br/>
            <span style={{color:'black'}}>{t('Common.usdc')}&nbsp;:&nbsp;<strong style={{color:'black'}}>0x5ba88c4852dd4774788e3d522c7a2ae78c898cd1</strong></span><br/>
            <span style={{color:'black'}}>{t('Common.btc')}&nbsp;&nbsp;:&nbsp;<strong style={{color:'black'}}>1DaUbS3HS86h2MZzoMvd8NeqCQypiCXvDG</strong></span>
          </div>
          {/* <Form.Label>
            <strong>{t("Common.banktransfer")}</strong>
          </Form.Label>
          <br /> */}

          {/* <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "25%" }}
            value={state.accoundDetails}
            viewBox={`0 0 256 256`} /> */}

        </Form.Group>
        {/* <Form.Group
          controlId="formBasicEmail"
        >
          <Form.Label>
            {t("Common.walletaddress")}
          </Form.Label>
          <br />

          <Form.Control
            type="text"
            value={state.accoundDetails}
            disabled={true}
          />
          {/* <Button className={style.RecieptRemoveButton}>
            <Links
              iscopy={true}
              icon="fa fa-files-o"
              link={state.accoundDetails}
            />
            Copy
          </Button> */}
        {/* </Form.Group>  */}

        <div className="receipt-section mt-1">
          <Form.Label className={style.required}>
            {t("Common.selectRecipt")}
          </Form.Label>
        </div>
        <Form.File onChange={onFileChange} />
        <Form.Text className={style.textdanger}>
          {t("Common.allowTypes")}
        </Form.Text>
        {state.url && (
          <Form.Group>
            <Image src={state.url} className={style.recieptPreview} />
            <Button className={style.RecieptRemoveButton} onClick={removeImage}>
              <i className="fa fa-times"></i>
              Remove
            </Button>
          </Form.Group>
        )}
        <Form.Group className={style.Buttons}>
          <Button
            onClick={uploadImgae}
            disabled={!state.url}
            className={style.uploadButton}
          >
            <i className="fa fa-arrow-circle-o-up"></i>
            {t("Button.upload")}
          </Button>

          <Button
            disabled={!state.enableFinish ? true : props.loader ? true : false}
            type="submit"
            className={style.NextButton}
          >
            {props.loader ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : (
              ""
            )}
            {t("Button.finish")}
          </Button>
        </Form.Group>
      </Form>
    </div>
  )
}

export default CryptoPayment