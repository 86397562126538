import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class DocumentsService{
    key =localStorage.getItem('apiKey')
    async getDocuments(lang_id,keyword){
        const response = await fetch(BASE_URL+'resources/documents?lang_id='+lang_id+'&keyword='+keyword, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
}