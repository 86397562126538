import React from 'react'
import style from "./ewallet.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Chart from "react-google-charts";
import { useState,useEffect } from 'react';
function Charts(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const Lang = useSelector((state) => state.Lang);
  const [chart, setChart] = useState([]);
  const { t } = useTranslation();

  let formatLabels=[]
  const convertData=()=>{
    formatLabels.push("Month");
    props?.data?.code?.map((item)=>{
      formatLabels.push((t("Common."+item)))
    })
  }
    
  useEffect(()=>{
    const ChartData=[]
    convertData();
    if(props?.data){
      ChartData.push(formatLabels)
      props?.data?.result?.map((item)=>{
      ChartData.push(item)
    })
    setChart(ChartData)
    }
    
  },[props?.data,Lang])
  
  const LineChartOptions = {
    allowHtml: true,
    hAxis: {
      title: "",
      textStyle: {
        color: currentTheme == "theme-dark" ? "#fff" : "#112237",
      },
    },
    vAxis: {
      title: "",
      baselineColor: currentTheme == "theme-dark" ? "#112237" : "",
      textStyle: {
        color: currentTheme == "theme-dark" ? "#fff" : "#112237",
      },
      gridlines: {
        color: currentTheme == "theme-dark" ? "transparent" : "black",
      },
    },
    colors: props?.data && props?.data.colors,
    series: {
      curveType: "function",
    },
    legend: {
      position: "bottom",
      textStyle: { color: currentTheme == "theme-dark" ? "#fff" : "#112237" },
    },
    chartArea: { width: "100%", left: 40 },
    backgroundColor: currentTheme == "theme-dark" ? "#112237" : "#fff",
  };

  return (
    <div>
      {
        (chart && chart.length>0)&&(
          <div className={style.summary}>
          <div className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_dashboard_pannel_box}`
                : `${style.dashboard_pannel_box}`
            }`}>
            {/* <TilesFilter items={filterData} onChange={props.filterChange} /> */}
            <div className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_dashboard_newsec_head}`
                  : `${style.dashboard_newsec_head}`
              }`}>
            </div>
            <div
              className={style.dashboard_newsec_grph_sec}
              style={{ overflow: "hidden" }}
            >
              <Chart
                width={"100%"}
                height={"300px"}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={chart}
                options={LineChartOptions}
                rootProps={{ "data-testid": "2" }}
              />

              {/* <canvas ref={chartRef} id="curve_chart" width="458" height="242" />
                            <ul  style={{marginTop:10,justifyContent:'center'}} className={style.legend_block} >
                                {legends &&
                                    legends.map((item,i) => {

                                        return (
                                            <li key={item.label} onClick={(event) => handleLegendClick(event, i)} className={style.legend}>
                                                <span
                                                    style={{
                                                      
                                                        backgroundColor: item.backgroundColor
                                                    }}
                                                />
                                               {item.label}
                                                
                                            </li>
                                        );
                                    })}
                            </ul> */}
            </div>
          </div>
        </div>
        )
      }
    </div>
  )
}

export default Charts