import Forms from "../../../components/auth/Forms";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Modal, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LoginService from "../../../../service/Auth/Login";
import { AlertMessage } from "../../../components/common";
import { useHistory } from "react-router";

export default function DeleteAccount() {
  const Service = new LoginService();
  const { t } = useTranslation();
  let history = useHistory();
  const [deleteAccount, setDeleteAccount] = useState(false);
  const openPopup = (status) => {
    if (
      state.userName === "" ||
      state.password === "" ||
      state.userName === null ||
      state.password === null
    ) {
      let err = {
        userName: "",
        password: "",
      };
      if (state.userName === "" || state.userName === null) {
        err.userName = "unamereq";
      }
      if (state.password === "" || state.password === null) {
        err.password = "required";
      }
      setState((prev) => ({
        ...prev,
        FormError: err,
      }));
    } else {
      setDeleteAccount(status);
    }
  };
  const [state, setState] = useState({
    userName: "",
    password: "",
    FormError: {
      userName: "",
      password: "",
    },
    notify: {
      show: false,
      message: "",
      type: "",
      header: "",
    },
  });
  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        show: false,
        message: "",
        type: "",
        header: "",
      },
    }));
  };

  onchange = (e) => {
    e.preventDefault();
    const { name, value } = e.target; //get the field name
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      FormError: {
        [name]: "",
      },
    }));
  };
  const showNotify = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        show: type === "success" ? false : type === "error" ? false : true,
      },
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: t(header),
        message: t(message),
      },
    }));
  };
  const onDeleteConfirm = async (data) => {
    await Service.DeleteAccount(data).then((res) => {
      if (res.status) {
        showNotify(
          "success",
          "success",
          `${res?.data?.description ?? "completed"}`
        );
        setTimeout(() => {
          history.push("/login");
        }, 3000);
      } else {
        showNotify(
          "error",
          "failed",
          `${res?.error?.description ?? "some error occured"}`
        );
        setDeleteAccount(false);
      }
    });
  };
  return (
    <div>
      <div>
        <Card
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "350px",
          }}
        >
          <center>
            <Card.Title style={{ paddingTop: "25px", fontSize: "20px" }}>
              DELETE ACCOUNT
            </Card.Title>
          </center>
          <Card.Body>
            <Forms
              type="text"
              name="userName"
              // inputClass={`${
              //   currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
              // }`}
              change={onchange}
              error={state.FormError.userName}
              value={state.userName}
            />
            <Forms
              type="password"
              name="password"
              // inputClass={`${
              //   currentTheme == "theme-dark" && `${style.dark_mode_textArea}`
              // }`}
              change={onchange}
              error={state.FormError.password}
              value={state.password}
            />
            <center>
              <Button
                variant="danger"
                style={{ textAlign: "center" }}
                onClick={() => {
                  openPopup(true);
                }}
              >
                Delete Account
              </Button>
            </center>
          </Card.Body>
        </Card>
      </div>

      {/* <br />
      <center>
        <div
          onClick={() => {
            history.push("/login");
          }}
        >
        to login
        </div>
      </center> */}
      {deleteAccount && (
        <>
          <Modal show={deleteAccount} onHide={() => openPopup(false)}>
            <Modal.Header closeButton style={{ backgroundColor: "#8862e0" }}>
              <Modal.Title style={{ color: "white" }}>
                {" "}
                {t("Common.delete_account")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "white" }}>
              {t("Common.delete_your_account_confirm")}
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "white" }}>
              <Button variant="secondary" onClick={() => openPopup(false)}>
                {t("Button.cancel")}
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  onDeleteConfirm({
                    username: state.userName,
                    password: state.password,
                  })
                }
              >
                {t("Button.confirm")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {state.notify.show && (
        <AlertMessage
          type={state.notify.type}
          message={state.notify.message}
          show={state.notify.show}
          dismiss={notifyDissmissed}
        />
      )}
    </div>
  );
}
