import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const API_KEY = localStorage.getItem("apiKey");
const DEFAULT_KEY = "649_";

// const API_URL = "http://192.168.21.19:5011/"; //local
// const API_URL = "http://162.19.146.132:1028/"  //demo
// const API_URL = "http://51.77.193.126:5000/"  //live-ip
const API_URL = "https://admin.belouga.io/"; //live

const BASE_URL = `${API_URL}api/web/`;
const IMG_URL = `${API_URL}api/uploads/images/`;
const BASE = `${API_URL}`;
const LEAD_URL = `${API_URL}`;
const MAINTENANCE = false

export {
  COMPANY_NAME,
  BASE_URL,
  IMG_URL,
  API_KEY,
  BASE,
  DEFAULT_KEY,
  LEAD_URL,
  MAINTENANCE,
};
